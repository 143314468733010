<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4">
            <v-card-text>
                <messages-list :status="status" :order_id="order_id"></messages-list>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "message-viewer",
    components: {
        MessagesList: () =>
            import("@components/messages/components/MessagesList"),
    },
    props: {
        status: {
            type: String,
            required: false,
            default: "",
        },
        order_id: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            item: null,
            page: {
                title: "Messages",
                icon: "mdi-message-text",
            },
            breadcrumbs: [
                {
                    text: "Messages",
                    disabled: false,
                    to: "#",
                },
            ],
        };
    },
    watch: {},
};
</script>

<style scoped>
</style>